import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@user/store/user'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomePage.vue'),
    meta: { layout: 'auth', auth: false },
  },
  {
    path: '/sign_in',
    name: 'SignIn',
    meta: { layout: 'auth', auth: false },
    component: () => import('../views/auth/SignIn.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    meta: { layout: 'auth', auth: false },
    component: () => import('../views/auth/HelpPage.vue'),
  },
  {
    path: '/auth_details',
    name: 'AuthDetails',
    meta: { layout: 'auth', auth: true },
    component: () => import('../views/auth/AuthDetails.vue'),
  },
  {
    path: '/authorize',
    name: 'Authorize',
    meta: { layout: 'auth', auth: false },
    component: () => import('../views/auth/AuthorizePage.vue'),
  },
  {
    path: '/profile',
    name: 'MyProfile',
    meta: { layout: 'main', auth: true },
    component: () => import('../views/MyProfile.vue'),
  },
  {
    path: '/support',
    name: 'ContactSupport',
    meta: { layout: 'main', auth: true },
    component: () => import('../views/ContactSupport.vue'),
  },
  {
    path: '/subsidies',
    name: 'Subsidies',
    meta: { layout: 'main', auth: false },
    redirect: () => {
      return { name: 'SubsidiesList' }
    },
    children: [
      {
        path: 'apply/:id',
        name: 'ApplySuibsidy',
        component: () => import('../views/subsidies/ApplySuibsidy.vue'),
      },
      {
        path: 'apply_draft/:id/:application_id',
        name: 'ApplyDraftSuibsidy',
        component: () => import('../views/subsidies/ApplySuibsidy.vue'),
      },
      {
        path: '',
        name: 'SubsidiesList',
        component: () => import('../views/subsidies/SubsidiesList.vue'),
      },
    ],
  },
  {
    path: '/applications',
    name: 'Applications',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'OpenSubsidies' }
    },
    children: [
      {
        path: 'list',
        name: 'ApplicationsList',
        component: () => import('../views/applications/ApplicationsList.vue'),
      },
      {
        path: ':id',
        name: 'ApplicationDetails',
        component: () => import('../views/applications/ApplicationDetails.vue'),
      },
      {
        path: 'appeal/:id',
        name: 'AppealApplication',
        component: () => import('../views/applications/AppealApplication.vue'),
      },
      {
        path: 'active',
        name: 'ActiveApplications',
        component: () => import('../views/applications/ApplicationsList.vue'),
      },
      {
        path: 'history',
        name: 'HistoryApplications',
        component: () => import('../views/applications/ApplicationsList.vue'),
      },
      {
        path: 'drafts',
        name: 'DraftApplications',
        component: () => import('../views/applications/ApplicationsList.vue'),
      },
    ],
  },
  {
    path: '/appeals',
    name: 'Appeals',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'OpenSubsidies' }
    },
    children: [
      {
        path: 'list',
        name: 'AppealsList',
        component: () => import('../views/appeals/AppealsList.vue'),
      },
      {
        path: ':id',
        name: 'AppealDetails',
        component: () => import('../views/appeals/AppealDetails.vue'),
      },
      {
        path: 'open',
        name: 'OpenAppeals',
        component: () => import('../views/appeals/AppealsList.vue'),
      },
      {
        path: 'closed',
        name: 'ClosedAppeals',
        component: () => import('../views/appeals/AppealsList.vue'),
      },
    ],
  },
  {
    path: '/fimas',
    name: 'Fimas',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'Fimas' }
    },
    children: [
      {
        path: 'add/:id',
        name: 'AddFimas',
        component: () => import('../views/paymentClaim/AddFimas.vue'),
      },
      {
        path: 'select/:id',
        name: 'SelectPreviousFimas',
        component: () => import('../views/paymentClaim/SelectPreviousFimas.vue'),
      },
      {
        path: 'preview/:id',
        name: 'FimasPreview',
        component: () => import('../views/paymentClaim/FimasPreview.vue'),
      },
      {
        path: 'update/:id',
        name: 'UpdateFimas',
        component: () => import('../views/paymentClaim/UpdateFimas.vue'),
      },
    ],
  },

  {
    path: '/payment-claims',
    name: 'PaymentClaims',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'PaymentClaims' }
    },
    children: [
      {
        path: 'list',
        name: 'ListOfPaymentClaims',
        component: () =>
          import('../views/paymentClaim/ListOfPaymentClaims.vue'),
      },
      {
        path: ':id',
        name: 'PaymentClaimDetails',
        component: () =>
          import('../views/paymentClaim/PaymentClaimDetails.vue'),
      },
      {
        path: 'file/:id',
        name: 'FilePaymentClaim',
        component: () => import('../views/paymentClaim/FilePaymentClaim.vue'),
      },
    ],
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: { layout: 'auth', auth: false },
    component: () => import('../views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
})

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.meta.auth

  const userStore = useUserStore()
  if (!userStore.isInitialized) {
    await userStore.fetchUser()
  }
  if (to.path === '/' && userStore.user) {
    await next({ name: 'Subsidies' })
  }
  if (requireAuth && !userStore.user && to.path !== '/sign_in') {
    await next({ name: 'SignIn' })
  } else {
    await next()
  }
})

export default router
